.quote-container {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.quote-content {
  position: relative;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quote-content .quote-text {
  width: 100%;
  margin-top: 25px;
  font-family: 'Proxima-Nova-Black';
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 58px;
}

.quote-content .bottom-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 4em;
  padding-right: 4em;
}

.quote-content .bottom-row .quote-user {
  margin-top: 0.5em;
}

.quote-content .left-quote {
  align-self: flex-start;
}
.quote-content .right-quote {
  align-self: flex-end;
}

@media only screen and (max-width: 400px) {
  .quote-content .quote-text {
    font-size: 16px;
    line-height: 24px;
  }
  .quote-content .bottom-row {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .quote-content .quote-user {
    font-size: 12px;
  }
  .quote-content .left-quote {
    width: 30px;
  }
  .quote-content .right-quote {
    width: 30px;
  }
}
