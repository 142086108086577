.navigation-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation-button-wrapper .navigation-button {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #2f74ff;
}

.navigation-button-wrapper .navigation-button .prev {
  transform: rotateX(180deg);
}

.navigation-button-wrapper .navigation-button svg {
  color: white;
  width: 2em;
  height: 2em;
}

.navigation-button-wrapper .nav-label {
  margin-top: 0.5em;
}
