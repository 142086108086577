.outcome-article-section img {
  object-fit: cover;
  height: 300px;
}

.outcome-article-section .outcome-article-item {
  cursor: pointer;
}

.outcome-article-section .outcome-article-container {
  padding-top: 1em !important;
}

@media only screen and (max-width: 400px) {
  .outcome-article-section > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .outcome-article-section .outcome-article-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    max-height: 300px;
    max-width: unset;
    padding-left: 0 !important;
    width: 100%;
  }

  .outcome-article-section .outcome-article-container {
    width: 100% !important;
    margin-left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    align-items: center;
    padding: 4px !important;
  }

  .outcome-article-section .outcome-article-description {
    text-align: center;
  }
}
