.manage-outcomes-page {
}

.manage-outcomes-page .tag-line {
  font-family: 'Proxima-Nova-Black';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #20212f;
  margin: 0 auto;
}

.manage-outcomes-page .page-quote {
  position: relative;
  overflow-y: hidden;
}

.manage-outcomes-page .static-image {
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .manage-outcomes-page .tag-line {
    font-size: 18px;
  }
}
