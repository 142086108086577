#outcome-logo-container {
  width: 100%;
  max-height: 800px;
  padding-top: 1em;
  padding-bottom: 1em;
  overflow-y: hidden;
  scroll-behavior: smooth;
  position: relative;
}

#outcome-logo-container #outcome-logo-page {
  width: 100%;
  max-width: unset;
  min-height: 100%;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

#outcome-logo-container .outcome-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

#outcome-logo-container .outcome-logo-row {
  display: flex;
  justify-content: center;
  height: 200px;
}

#outcome-logo-container .outcome-logo-row.odd {
  padding-left: 5em;
  padding-right: 5em;
}

#outcome-logo-container .outcome-logo-row.even {
  /* border: 1px solid green; */
}

#outcome-logo-container .outcome-logo-img {
  width: 60%;
  object-fit: scale-down;
  object-position: center;
}

#outcome-logo-container .logo-container {
  /* border: 1px solid black; */
}

@media only screen and (max-width: 400px) {
  .action-section {
    justify-content: space-evenly !important;
  }
}
