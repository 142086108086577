:root {
  --primary: #e5173f;
  --dark: #34333b;
  --white: #ffffff;
  --black: #000000;
  --grey: #c0c7d5;
  --light: #808080;

  --primary-rgba: rgba(229, 23, 63, 0.8);
}
