.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  background: green;
}

.custom-indicator {
  bottom: -70px;
}

.custom-indicator li {
  width: 50px;
  height: 50px;
}

.remove-duplicates-single .slick-cloned {
  display: none!important;
}

.remove-single-response .slick-cloned {
  display: none!important;
}