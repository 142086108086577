@font-face {
  font-family: 'Proxima-Nova-Black';
  src: url('../../public/assets/fonts/Proxima-Nova-Black.otf');
}

a {
  text-decoration: none;
  color: #000000;
}

main {
  margin-bottom: 30px;
  overflow-x:hidden;
  overflow-y:hidden;
}

div#root {
  overflow-x:hidden;
  overflow-y:hidden;
}

.questions-slider .slick-list {
  z-index: 1000;

}

@media screen and (max-width: 1400px) {
  .questions-slider .slick-list {
    width:140%;
    margin-left:-20%;
  }
}
@media screen and (max-width: 1200px) {
  .questions-slider .slick-list {
    width:140%;
    margin-left:-40%;
  }
}
@media screen and (max-width: 500px) {
  .questions-slider .slick-list {
    width:100%;
    margin-left:0
  }
}


.family_members_grade_bands {
  border-bottom-left-radius: 15px;
}

/* .qa-video-slider .slick-track {
  height: 750px;
} */

.qa-video-slider {
  max-width: 100%;
  position: relative;
}

.qa-video-slider .slick-slide > div {
  max-width: 80%;
  margin: 0 auto;
}

.qa-video-slider .slick-slide:not(.slick-current) > div {
  max-width: 77%;
}

.qa-video-slider .slick-track {
  padding-left: 20vw;
}

.qa-video-slider .slick-current {
  width: calc(91vw) !important;
  margin-left: -20vw;
  padding-left: 5vw;
}

@media screen and (max-width: 1300px) {
  .qa-video-slider .slick-current {
    width: calc(88vw) !important;
  }
  .qa-video-slider .slick-slide > div {
    width: 88vw;
  }
}

@media screen and (max-width: 700px) {
  .qa-video-slider .slick-arrow {
    display:none !important;
  }
}

.qa-video-slider .slick-current + .slick-slide {
  margin-left: -15vw;
}


@media only screen and (max-width: 700px) {
  .logo {
    width: 200px;
  }
  .thumbs-wrapper.axis-vertical {
    display: none;
  }
  ul.control-dots {
    display: none;
  }
  p.carousel-status {
    display: none;
  }
  button.control-arrow.control-next {
    height: 630px;
  }
  .carousel .control-prev.control-arrow {
    height: 638px;
  }
  button.control-arrow.control-next {
    height: 350px;
  }
  .qa-video-slider .slick-track {
    height: unset;
  }
  /* div.slider.wrapper */
}

h1,
.img-text {
  color: white;
  width: 155px;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 40%;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-weight: bold;
  cursor: pointer;
}

.pic-box {
  position: relative;
  overflow: hidden;
  width: '100%';
}

.pic-box:hover img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
}

.pic-box:hover .img-text {
  -webkit-opacity: 1;
  opacity: 1;
}

.realAnswerBox::-webkit-scrollbar {
  display: auto;
}
.carousel .carousel-status {
  display: none;
}
.carousel .control-dots {
  display: none;
}
.carousel .thumb.selected {
  border-bottom: 5px solid #2e75ff;
}

.carousel ul li {
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

li.slide {
  min-height: 600px;
}
.video-wrap {
  width: 70% !important;
}
/* .slick-slide .qavideos {
  /* height: 500px !important; */
/* } */

.slick-arrow:nth-child(1) {
  left: -50px !important;
  width: 70px !important;
  z-index: 9999 !important;
}

@media only screen and (max-width: 500px) {
  li.slide {
    min-height: 300px;
  }
  /* .logo {
    /* width: 130px; */
  /* } */
  .ourschool-banner {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .beyondacd {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .mob-muibutton-enroll {
    margin-left: 25px;
  }
  .clubhub-mob {
    margin-left: -30px !important;
  }
  /* h2.MuiTypography-root.MuiTypography-h2.css-bxqr6c-MuiTypography-root{
    font-size: 40 !important;
  } */
  /* .MuiGrid-root .MuiGrid-container .css-1gh2fm1-MuiGrid-root{
    justify-content: flex-start !important;
  } */
  .searchandconnectmob {
    flex-wrap: nowrap !important;
  }
  .millerhs-button {
    justify-content: center !important;
  }
  .flex-horz {
    flex-wrap: nowrap !important;
  }
  .small-circle {
    height: 90px;
  }
  .small-circle-2 {
    height: 180px;
  }
  .qavideos {
    height: 280px !important;
  }
  .slick-arrow {
    width: 30px !important;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.8)) !important ;
  }
  .overflow-questionBox {
    overflow: hidden !important;
  }
  .video-wrap {
    width: 100% !important;
  }
  .play-btn {
    width: 40px !important;
    height: 40px !important;
  }
  .slick-arrow:nth-child(1) {
    left: -20px !important;
  }
}

.buttons {
  background: white !important;
  color: black !important;
  transition: 700ms;
}
.buttons:hover {
  /* background: white !important; */
  /* color: orangered !important; */
  transform: scale(1.1);
}

/* signin pages */

.MuiButton-signIn {
  border-radius: 50px !important;
  color: black !important;
  background: white !important;
  border: 1px solid black !important;
  width: 300px;
  justify-content: flex-start !important;
}
.MuiButton-signUp {
  border-radius: 50px !important;
  color: black !important;
  background: white !important;
  border: 1px solid black !important;
  width: 290px;
  justify-content: flex-start !important;
}

.MuiButton-startIcon {
  width: 50px;
}

.muibuttonfb {
  margin-left: -8px !important;
}

/* video screen size */

.slick-list {
  padding: 0px 0px !important;
}
.slick-arrow {
  background: none !important;
}
.answerspng {
  position: absolute;
  left: 45%;
  right: 0;
  z-index: 11;
  top: -8%;
}
.margBtm {
  margin-bottom: 60px !important;
}

#demo-simple-select ~ * {
  border: none !important;
}

p a {
  color: blue;
  text-decoration: underline;
}

.take-selfie-box video {
  width: 100%;
}

.family-input-fields input[name='familyMember1Degree'],
.family-input-fields input[name='familyMember1DegreeName'],
.family-input-fields input[name='familyMember1DegreeSchool'],
.family-input-fields input[name='last_name'],
.family-input-fields input[name='members0.name'],
.family-input-fields input[name='members1.name'],
.family-input-fields input[name='members2.name'],
.family-input-fields input[name='members3.name'],
.family-input-fields input[name='primaryEmail'],
.family-input-fields input[name='otherEmail'],
.family-input-fields input[name='familyMember1'],
.family-input-fields input[name='familyMember2'],
.family-input-fields input[name='familyMember2Degree'],
.family-input-fields input[name='familyMember2DegreeName'],
.family-input-fields input[name='linkedinSocial-0'],
.family-input-fields input[name='linkedinSocial-1'],
.family-input-fields input[name='linkedinSocial-2'],
.family-input-fields input[name='linkedinSocial-3'],
.family-input-fields input[name='linkedinSocial-4'],
.family-input-fields input[name='linkedinSocial-5'],
.family-input-fields input[name='facebookSocial'],
.family-input-fields input[name='twitterSocial'],
.family-input-fields input[name='favoriteBook'],
.family-input-fields input[name='favoriteRestaurant'],
.family-input-fields input[name='favoriteFood'],
.family-input-fields input[name='favoriteSport'],
.family-input-fields input[name='favoriteHoliday'],
.family-input-fields input[name='favoritePractice'],
.family-input-fields input[name='perfectFamilyPlan'],
.family-input-fields input[name='about.0.value'],
.family-input-fields input[name='about.1.value'],
.family-input-fields input[name='about.2.value'],
.family-input-fields input[name='about.3.value'],
.family-input-fields input[name='about.4.value'],
.family-input-fields input[name='about.5.value'],
.family-input-fields input[name='about.6.value'],
.family-input-fields input[name='about.7.value'],
.family-input-fields input[name='last_name'],
.family-input-fields input[name='primary_email'],
.family-input-fields input[name='secondary_email'],
.family-input-fields input[name='favoriteMovie'] {
  padding-top: 10px !important;
  height: 38px;
}


.club-listing-featured-container {
  padding-left:30%;
  padding-bottom:60px;
}
.club-listing-featured-image {
  width:1000px;
  height:500px;
  object-fit: cover;
  object-position: center center;
  margin-bottom:10px;
}
.club-cover-image {
  width: 100%;
  height: 100%;
  max-height:600px;
  max-width:100%;
  object-fit: contain;
  object-position: left center;
}
.club-cover-container {
  margin-left:15%;
  margin-right:15%;
  width:70%;
  padding:1em;
}
@media (min-width: 1280px) {
  .club-cover-container {
    margin-left:20%;
    width:100%;
  }
}
@media (max-width: 1280px) {
  .club-listing-featured-image {
    width:100%;
    height:auto;
  }
  .club-listing-featured-container {
    padding-left:1em;
    padding-right:1em;
  }
  .club-cover-container {
    width:100%;
    margin-left:0;
  }
}
.club-leader-card {
  margin-right: 10px;
  margin-bottom:50px;
  position:relative;
  overflow:visible !important;
}
.club-leader-image {
  max-height:400px;
  max-width:400px;
  object-fit:cover;
  background:#CCC;
}
.club-leader-container.big .club-leader-image {
  max-height:600px;
  max-width:600px;
}
.club-leader-text {
  position:absolute;
  left:100%;
  top:2em;
  width:100%;
}
.club-leader-container.big .club-leader-text {
  left:0;
  top:auto;
  bottom:-5em;
}
.club-leader-remove {
  position:absolute;
  top: 4px;
  right: 4px;
  z-index:100;
}
.club-leader-container.big .club-leader-remove {
  left:35em;
}
@media (max-width: 1280px) {
  .club-leader-text {
    position:static !important;
    padding-left:0;
  }
}


.club-events-container {
  height:650px;
  position:relative;
}
.club-events-table {
  width:100%;
  position:static;
  height:500px;
  padding-bottom:20px;
  overflow:auto;
}
@media (max-width: 1280px) {
  .club-events-container {
    height:1050px;
  }
}
.ChatListItem {
  width: 100%;
  display: flex;
  background-color: white;
  transition: 200ms all ease-in-out;
  align-items: center;
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.ChatListItem:hover {
  background-color: #f9f9f9;
}

.MessageBubble {
  width: 70%;
  border-radius: 20px;
  padding: 1em;
  margin-bottom: 1em;
}

.MessageBox input {
  padding-right: 40px;
}

.activity-icon {
  width:50px;
  height:50px;
  object-fit:contain;
}


.header-image-overlay {
  position: relative;
  width: 100%;
  height: auto;
}

.header-image-overlay::before {
  content:' ';
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
}

@media screen and (max-width: 1280px) {
  .header-image-overlay::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
  }
}


.top-banner-container {
  display:flex;
  width:100%;
  justify-content: space-between;

  .top-banner {
    width:50%;
    color:#FFF;
    text-align:center;
    padding:.3em;
  }
  .top-banner:only-child {
    width:100%;
  }
}

@media screen and (max-width: 1280px) {
  .top-banner-container {
    flex-direction:column;
    .top-banner {
      width:100%;
    }
  }
}