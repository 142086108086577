

.carousel.carousel-slider .control-dots {
  display: block;
}

.slider li.slide {
  height: unset;
}

.carousel .slide .carousel-container .custom-legend {
  width: unset;
  opacity: 1;
  left: 60%;
  margin-left: unset;
  background: unset;
  color: unset;
  font-family: Proxima-Nova, sans-serif;
  font-weight: 900;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow.carousel-action-buttons {
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  height: unset;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow.carousel-action-buttons:hover {
  background: rgba(0, 0, 0, 0.5);
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow.carousel-action-buttons:before {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}


.carousel .control-arrow, .carousel.carousel-slider .control-arrow.carousel-action-buttons.left:before {
  border-left: 16px solid #fff;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow.carousel-action-buttons.right:before {
  border-right: 16px solid #fff;
}

@media screen and (max-width: 650px){
  .custom-legend {
    position: static!important;
    margin-bottom: 35px!important;
  }
}