.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.grid-item {
  padding: 20px;
}


[class*='grid'],
[class*='colz-'],
[class*='mobile-'],
.grid:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

[class*='colz-'] {
  float: left;
  min-height: 1px;
  padding-right: 20px; /* colzumn-space */
}

[class*='colz-'] [class*='colz-']:last-child {
  padding-right: 0;
}

.grid {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.grid:after {
  content: "";
  display: table;
  clear: both;
}

.push-right {
  float: right;
}

/* Content colzumns */

.colz-1-1 {
  width: 100%;
}
.colz-2-3, .colz-8-12 {
  width: 66.66%;
}

.colz-1-2, .colz-6-12 {
  width: 50%;
}

.colz-1-3, .colz-4-12 {
  width: 33.33%;
}

.colz-1-4, .colz-3-12 {
  width: 25%;
}

.colz-1-5 {
  width: 20%;
}

.colz-1-6, .colz-2-12 {
  width: 16.667%;
}

.colz-1-7 {
  width: 14.28%;
}

.colz-1-8 {
  width: 12.5%;
}

.colz-1-9 {
  width: 11.1%;
}

.colz-1-10 {
  width: 10%;
}

.colz-1-11 {
  width: 9.09%;
}

.colz-1-12 {
  width: 8.33%
}

/* Layout colzumns */

.colz-11-12 {
  width: 91.66%
}

.colz-10-12 {
  width: 83.333%;
}

.colz-9-12 {
  width: 75%;
}

.colz-5-12 {
  width: 41.66%;
}

.colz-7-12 {
  width: 58.33%
}

/* Pushing blocks */

.push-2-3, .push-8-12 {
  margin-left: 66.66%;
}

.push-1-2, .push-6-12 {
  margin-left: 50%;
}

.push-1-3, .push-4-12 {
  margin-left: 33.33%;
}

.push-1-4, .push-3-12 {
  margin-left: 25%;
}

.push-1-5 {
  margin-left: 20%;
}

.push-1-6, .push-2-12 {
  margin-left: 16.667%;
}

.push-1-7 {
  margin-left: 14.28%;
}

.push-1-8 {
  margin-left: 12.5%;
}

.push-1-9 {
  margin-left: 11.1%;
}

.push-1-10 {
  margin-left: 10%;
}

.push-1-11 {
  margin-left: 9.09%;
}

.push-1-12 {
  margin-left: 8.33%
}

@media handheld, only screen and (max-width: 767px) {
  .grid {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px; /* grid-space to left */
    padding-right: 10px; /* grid-space to right: (grid-space-left - colzumn-space) e.g. 20px-10px=10px */
  }

  [class*='colz-'] {
    width: auto;
    float: none;
    margin: 10px 0;
    padding-left: 0;
    padding-right: 10px; /* colzumn-space */
  }

  [class*='colz-'] [class*='colz-'] {
    padding-right: 0;
  }

  /* Mobile Layout */

  [class*='mobile-colz-'] {
    float: left;
    margin: 0 0 10px;
    padding-left: 0;
    padding-right: 10px; /* colzumn-space */
    padding-bottom: 0;
  }

  .mobile-colz-1-1 {
    width: 100%;
  }
  .mobile-colz-2-3, .mobile-colz-8-12 {
    width: 66.66%;
  }

  .mobile-colz-1-2, .mobile-colz-6-12 {
    width: 50%;
  }

  .mobile-colz-1-3, .mobile-colz-4-12 {
    width: 33.33%;
  }

  .mobile-colz-1-4, .mobile-colz-3-12 {
    width: 25%;
  }

  .mobile-colz-1-5 {
    width: 20%;
  }

  .mobile-colz-1-6, .mobile-colz-2-12 {
    width: 16.667%;
  }

  .mobile-colz-1-7 {
    width: 14.28%;
  }

  .mobile-colz-1-8 {
    width: 12.5%;
  }

  .mobile-colz-1-9 {
    width: 11.1%;
  }

  .mobile-colz-1-10 {
    width: 10%;
  }

  .mobile-colz-1-11 {
    width: 9.09%;
  }

  .mobile-colz-1-12 {
    width: 8.33%
  }

  /* Layout colzumns */

  .mobile-colz-11-12 {
    width: 91.66%
  }

  .mobile-colz-10-12 {
    width: 83.333%;
  }

  .mobile-colz-9-12 {
    width: 75%;
  }

  .mobile-colz-5-12 {
    width: 41.66%;
  }

  .mobile-colz-7-12 {
    width: 58.33%
  }

  .hide-on-mobile {
    display: none !important;
    width: 0;
    height: 0;
  }
}

