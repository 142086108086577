.files-listing .slick-arrow::before {
  content: unset;
}

.files-listing .slick-arrow:nth-child(1) {
  left: -25px!important;
}

.files-listing .slick-list {
  width: 96%;
  margin-left: 10px;
}
